<template>
  <div class="orders">
    <div class="df-row">
      <!-- <div class="df-col">
        <el-select
          v-model="submitData.balance"
          placeholder="是否结算佣金"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in [
              { label: '全部', value: 0 },
              { label: '已结算', value: 1 },
              { label: '未结算', value: 2 },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->

      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <el-input
          v-model="submitData.work"
          placeholder=""
          size="mini"
          clearable
        ></el-input>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch"
          >查 询</el-button
        >
      </div>
    </div>

    <el-table
      :data="tableData"
      :max-height="tableMaxHeight"
      size="mini"
      border
      stripe
      v-loading="loading"
    >
      <el-table-column prop="" label="订单时间" width="150">
        <template slot-scope="scope">{{
          scope.row.CreateTime
            ? util.dateFormat(
                "YYYY-mm-dd HH:MM:SS",
                new Date(scope.row.CreateTime * 1000)
              )
            : ""
        }}</template>
      </el-table-column>
      <el-table-column
        prop="OrderRefNo"
        label="No"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="OrderAmount"
        label="订单金额"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="OrderTypeName"
        label="订单类型"
        width="80"
      ></el-table-column>
      <el-table-column prop="" label="会员信息" width="120">
        <template slot-scope="scope">
          <div class="member-info">
            <span>{{ scope.row.MemberName }}</span>
            <span>{{ scope.row.MemberPhone }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="分销商信息">
        <template slot-scope="scope">
          <div v-if="scope.row.FirstDealerId > 0" class="dealer-info">
            <div class="frist">
              <span
                >一级: {{ scope.row.FirstDealerName }}(ID:
                {{ scope.row.FirstDealerId }})</span
              >
              <span>¥{{ scope.row.FirstDealerAmount }}</span>
            </div>
            <div v-if="scope.row.SecondDealerId > 0" class="second">
              <span
                >二级: {{ scope.row.SecondDealerName }}(ID:
                {{ scope.row.SecondDealerId }})</span
              >
              <span>¥{{ scope.row.SecondDealerAmount }}</span>
            </div>
            <div v-if="scope.row.ThirdDealerId > 0" class="third">
              <span
                >三级: {{ scope.row.ThirdDealerName }}(ID:
                {{ scope.row.ThirdDealerId }})</span
              >
              <span>¥{{ scope.row.ThirdDealerAmount }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="Remark"
        label="备注"
        width="260"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="是否结算" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.SettleMark ? "已结算" : "未结算" }}</span><br>
          <span v-show="scope.row.DeleteMark" class="table-red-font">已删除</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      :page-sizes="[5, 10, 15, 20]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import Dealer from "@/api/dealer.js";

export default {
  data() {
    return {
      tableMaxHeight: "",
      totalRecord: 0,
      submitData: {
        work: "",
        begin_date: "",
        end_date: "",
        // balance: 0,
        page_index: 1,
        page_size: 10,
      },
      multipleDate: [],
      tableData: [{}],
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.onChangeDate();
    this.getDealerOrderList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取分销订单列表
    async getDealerOrderList() {
      try {
        let { data } = await Dealer.getDealerOrderList(this.submitData);
        this.tableData = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 68 + 64);
    },

    onSearch() {
      this.submitData.page_index = 1;
      this.getDealerOrderList();
    },

    // 日期选择器改变时间
    onChangeDate() {
      this.submitData.begin_date =
        this.multipleDate.length > 0
          ? this.multipleDate[0]
          : this.util.getNowMonthToday();
      this.submitData.end_date =
        this.multipleDate.length > 0
          ? this.multipleDate[1]
          : this.util.getNowMonthToday();
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      // this.getVipDonaItemList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      // this.getVipDonaItemList();
    },
  },
};
</script>

<style lang="less" scoped>
.orders {
  padding-top: 20px;

  .el-table {
    .goods-info,
    .member-info,
    .dealer-info {
      .flex-col;
      align-items: unset;
    }

    .dealer-info {
      > div {
        // padding: 10px 0;

        span {
          margin-right: 10px;
        }

        &:last-child {
          border: 0;
        }
      }
    }
  }

  .el-pagination {
    float: right;
    margin-top: 20px;
  }
}
</style>
